.card-section ul li {
  list-style: none;
}


.catalogue-medium {
  overflow: hidden;
  padding: 0;
  position: relative;
  height: 35vh;
  background: no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  line-height: 1.8;

  .dark-overlay {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 11;
    background: rgba(0, 0, 0, 0.5);
    -webkit-transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
    -moz-transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
    -o-transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
    transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
  }
  &:hover .dark-overlay {
    opacity: 1;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  .info {
    font-size: 20px;
    top: 0;
    left: 0;
    position: absolute;
    width: auto;
    z-index: 11;
    background: #fff;
    padding: 0.5em;
  }
  .tittle-bottom {
    position: absolute;
    width: auto;
    z-index: 11;
    bottom: 2em;
    -webkit-transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
    -moz-transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
    -o-transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
    transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
  }
  .tittle-top {
    width: auto;
    padding: 3em 3em 0.5em 3em;
    z-index: 11;
    -webkit-transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
    -moz-transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
    -o-transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
    transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &p {
    font-size: 18px;
  }
  &.more {
    font-size: 22px;
  }
  &.description {
    padding: 0 3em;
    overflow: auto;
    max-height: 300px;
    width: auto;
    z-index: 11;
    -webkit-transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
    -moz-transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
    -o-transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
    transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
  }
  &.social {
    bottom: 0;
    position: absolute;
    padding: 1em 3em;
    width: 100%;
    z-index: 11;
    text-align: left;
    /*background: #f4f4f4;*/
    -webkit-transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
    -moz-transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
    -o-transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
    transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
  }
  &.social i {
    margin-right: 1em;
  }
}
// END OF CATALOGUE