#login {
  width: 80%;
  position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  max-width: 400px;
  z-index: 30;
  -webkit-transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
  -moz-transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
  -o-transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
  transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
}