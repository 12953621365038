#sideMenu ul li span {
  font-size: 12px;
}

#sideMenu ul li a {
  border-radius: 0.4em;
  -webkit-transition: all 800ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -moz-transition: all 800ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -o-transition: all 800ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  transition: all 800ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
}

#sideMenu ul li a:hover {
  background: rgba(228, 228, 228, 0.918);
}

#sideMenu li a.active {
  background: rgba(228, 228, 228, 0.918)
}

#sideMenu ul li img {
  width: 50%;
  top: 50%;
  left: 50%;
  position: relative;
  transform: translate(-50%);
}


#offCanvasMenu.is-closed ul.menu li{
  opacity: 0;
  position: relative;
  left: -20px;

  -webkit-transition: all 500ms ease-in;
  -moz-transition: all 500ms ease-in;
  -o-transition: all 500ms ease-in;
  transition: all 500ms ease-in;

  -webkit-transition-delay: 100ms;
  -moz-transition-delay: 100ms;
  -o-transition-delay: 100ms;
  transition-delay: 100ms;
}

#offCanvasMenu.is-open ul.menu li{
  opacity: 1;
  position: relative;
  left: 08;

  -webkit-transition: all 500ms ease-in;
  -moz-transition: all 800ms ease-in;
  -o-transition: all 800ms ease-in;
  transition: all 800ms ease-in;

  -webkit-transition-delay: 200ms;
  -moz-transition-delay: 200ms;
  -o-transition-delay: 200ms;
  transition-delay: 200ms;
}


/* Small only */
@media screen and (max-width: 39.9375em) {


}

/* Medium and up */
@media screen and (min-width: 40em) {

}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {

}

/* Large and up */
@media screen and (min-width: 64em) {

}

/* Large only */
@media screen and (min-width: 64em) and (max-width: 74.9375em) {

}