.drawerFix{
  position: relative;
  width: 300px;
  &.MuiDrawer-paper-96{
    width: 300px;
    position: relative;
  }
  &.MuiDrawer-paperAnchorRight-98 {
    left: auto;
    right: auto;
  }
  &.MuiPaper-root-10{
    background-color: none;
  }
}

.MuiTab-root-121{
  height: 64px;
}

.MuiBottomNavigation-root-272{
  width: 100%
}

.MuiTabs-centered-246 {
  height: 64px;
}