@charset "UTF-8";

$red: #993737;
$green: #4ba546;
$amber: #F39C00;
$blue: #27aae2;

$first-color: #ff6a00;
$second-color: #244188;
$third-color: #622774;
$forth-color: #462c4e;
$fifth-color: #462c4e;

$light-gray: #fafafa;
$medium-gray: #ccc;
$dark-gray: #191919;

$global-spacing: 1em;
$global-radius: 0.2em;

/*#################### THEME SETTINGS #########################*/



@mixin transition($mothod) {
    -webkit-transition: $mothod;
    -moz-transition: $mothod;
    -ms-transition: $mothod;
    -o-transition: $mothod;
    transition: $mothod;
}

/*#### FONT SETTINGS ####*/

@font-face {
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    src: local('Lato Light'), local(Lato-Light), url(/fonts/lato/KT3KS9Aol4WfR6Vas8kNcg.woff) format("woff")
}

@font-face {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    src: local('Lato Regular'), local(Lato-Regular), url(/fonts/lato/9k-RPmcnxYEPm8CNFsH2gg.woff) format("woff")
}

@font-face {
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    src: local('Lato Bold'), local(Lato-Bold), url(/fonts/lato/wkfQbvfT_02e2IWO3yYueQ.woff) format("woff")
}

.lato {
    font-family: Lato, sans-serif;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.lato-reg {
    font-family: Lato, sans-serif;
    font-weight: 400;
}

.lato-bold {
    font-family: Lato, sans-serif;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
h7 {
    font-family: Lato, sans-serif;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: 'Lato';
    font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6,
h7 {
    font-family: Lato, sans-serif;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: Lato, sans-serif;
    font-weight: 400;
}

.strong {
    font-weight: 700;
}

.light {
    font-weight: 300;
}

.uppercase {
    text-transform: uppercase;
}

.line-through {
    text-decoration: line-through;
}

a {
    outline: none;
}

.f-6 {
    font-size: 6px
}

.f-8 {
    font-size: 8px
}

.f-10 {
    font-size: 10px
}

.f-12 {
    font-size: 12px
}

.f-14 {
    font-size: 14px
}

.f-16 {
    font-size: 16px
}

.f-18 {
    font-size: 18px
}

.f-20 {
    font-size: 20px
}

.f-22 {
    font-size: 22px
}

.f-24 {
    font-size: 24px
}

.f-26 {
    font-size: 26px
}

.f-28 {
    font-size: 28px
}

.f-30 {
    font-size: 30px
}

.f-34 {
    font-size: 34px
}

.f-36 {
    font-size: 36px
}

.center {
    text-align: center;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.justify {
    text-align: justify;
}

/* #### MARGING CUSTOM PARAMS #### */

.margin-top {
    margin-top: $global-spacing;
}

.margin-top-2x {
    margin-top: $global-spacing * 2;
}

.margin-top-3x {
    margin-top: $global-spacing * 3;
}

.margin-bottom {
    margin-bottom: $global-spacing;
}

.margin-bottom-2x {
    margin-bottom: $global-spacing * 2;
}

.margin-bottom-3x {
    margin-bottom: $global-spacing * 3;
}

.margin-right {
    margin-right: $global-spacing;
}

.margin-right-2x {
    margin-right: $global-spacing * 2;
}

.margin-left {
    margin-left: $global-spacing;
}

.margin-left-2x {
    margin-left: $global-spacing * 2;
}

.margin-distributed {
    margin: $global-spacing 0;
}

.margin-distributed-2x {
    margin: $global-spacing * 2 0;
}

.margin-distributed-3x {
    margin: $global-spacing * 3 0;
}

.margin-all {
    margin: $global-spacing;
}

.margin-all-2x {
    margin: $global-spacing * 2;
}

.margin-all-3x {
    margin: $global-spacing * 3;
}

.no-margin {
    margin: 0;
}

/* #### PADDING CUSTOM PARAMS #### */

.padding-top {
    padding-top: $global-spacing;
}

.padding-top-2x {
    padding-top: $global-spacing * 2;
}

.padding-bottom {
    padding-bottom: $global-spacing;
}

.padding-bottom-2x {
    padding-bottom: $global-spacing * 2;
}

.padding-distributed {
    padding: $global-spacing 0;
}

.padding-distributed-2x {
    padding: $global-spacing * 2 0;
}

.padding-distributed-3x {
    padding: $global-spacing * 3 0;
}

.padding-all {
    padding: $global-spacing;
}

.padding-all-2x {
    padding: $global-spacing * 2;
}

.padding-all-3x {
    padding: $global-spacing * 3;
}

.padding-left {
    padding: 0 0 0 $global-spacing;
}

.no-padding {
    padding: 0;
}

p {
    margin: 10px 0;
    font-size: 14px;
    color: $dark-gray;
}

div,
a,
.reveal {
    outline: none;
}

hr {
    border-bottom: 1px solid $medium-gray;
}

hr:before {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: $medium-gray;
    border-width: 7px;
    margin-top: -7px;
    margin-left: 1px;
    border-radius: 2px;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.dropdown-pane:before {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: $medium-gray;
    border-width: 7px;
    margin-top: -23px;
    margin-left: -23px;
    border-radius: 2px;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* ################ BROWSER DEFAULT OVERWRITES ###################*/

::-webkit-scrollbar {
    display: none;
    /*width:1px;
height:5px;*/
}

a {
    text-decoration: none;
    outline: none;
}

::-webkit-scrollbar-button:end:increment,
::-webkit-scrollbar-button:start:decrement {
    display: none
}

::-webkit-scrollbar-track-piece {
    background: rgba(0, 0, 0, 0.8);
}

::-webkit-scrollbar-thumb:vertical {
    // background: url(/images/ui/SiteScroll.png) no-repeat center #666
    background:#666;
}

::-webkit-clear-button {
    display: none;
    -webkit-appearance: none;
    appearance: none;
}

::input-placeholder {
    color: $dark-gray;
}

::-webkit-input-placeholder {
    color: $dark-gray;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: $dark-gray;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: $dark-gray;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: $dark-gray;
}

code[class*=language-],
pre[class*=language-] {
    color: #000;
    text-shadow: 0 1px #fff;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}

code[class*=language-],
pre[class*=language-] {
    color: #000;
    text-shadow: 0 1px #fff;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    box-shadow: 0 0 0px 1000px white inset;
    background-color: none !important;
    color: #ff6a00;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

/*#################### COLOURS #######################*/

.color_1 {
    color: $first-color;
}

.color_2 {
    color: $second-color;
}

.color_3 {
    color: $second-color;
}

.color_4 {
    color: $forth-color;
}

.color_5 {
    color: $fifth-color;
}

.gray_1 {
    color: $light-gray;
}

.gray_2 {
    color: $medium-gray
}

.gray_3 {
    color: $dark-gray;
}

.gray_1-bg {
    background-color: $light-gray;
}

.gray_2-bg {
    background-color: $medium-gray
}

.gray_3-bg {
    background-color: $dark-gray;
}

.gray_1-border {
    border: solid 1px $light-gray;
}

.gray_1-border-bottom {
    border-bottom: solid 1px $light-gray;
}

.gray_2-border {
    border: solid 1px $medium-gray
}

.gray_2-border-bottom {
    border-bottom: solid 1px $medium-gray;
}

.gray_3-border {
    border: solid 1px $dark-gray;
}

.color_1-bg {
    background-color: $first-color;
}

.color_2-bg {
    background-color: $second-color;
}

.color_3-bg {
    background-color: $second-color;
}

/* Medium Purple*/

.color_4-bg {
    background-color: $forth-color;
}

.color_5-bg {
    background-color: $fifth-color;
}

/*####### OTHER COLOURS #######*/

.no-border {
    border: none;
}

.color_1-border {
    border: solid 1px $first-color;
}

.color_2-border {
    border: solid 1px $second-color;
}

.color_3-border {
    border: solid 1px $second-color;
}

.color_4-border {
    border: solid 1px $forth-color;
}

.color_5-border {
    border: solid 1px $fifth-color;
}

.color_1_border-bottom {
    border-bottom: solid 1px $first-color;
}

.color_2_border-bottom {
    border-bottom: solid 1px $second-color;
}

.color_3_border-bottom {
    border-bottom: solid 1px $second-color;
}

.color_4_border-bottom {
    border-bottom: solid 1px $forth-color;
}

.color_5_border-bottom {
    border-bottom: solid 1px $fifth-color;
}

.color_1_border-left {
    border-left: solid 1px $first-color;
}

.color_2_border-left {
    border-left: solid 1px $second-color;
}

.color_3_border-left {
    border-left: solid 1px $second-color;
}

.color_4_border-left {
    border-left: solid 1px $forth-color;
}

.color_5_border-left {
    border-left: solid 1px $fifth-color;
}

.color_1_border-right {
    border-right: solid 1px $first-color;
}

.color_2_border-right {
    border-right: solid 1px $second-color;
}

.color_3_border-right {
    border-right: solid 1px $second-color;
}

.color_4_border-right {
    border-right: solid 1px $forth-color;
}

.color_5_border-right {
    border-right: solid 1px $fifth-color;
}

.red {
    color: $red;
}

.red-bg {
    background-color: $red;
}

.red-border {
    border: solid 1px $red;
}

.green {
    color: $green
}

.green-bg {
    background-color: $green;
}

.green-border {
    border: solid 1px $green;
}

.amber {
    color: $amber;
}

.amber-bg {
    background-color: $amber;
}

.blue {
    color: $blue;
}

.blue-bg {
    background-color: $blue;
}

.blue-border {
    border: solid 1px $blue;
}

.black {
    color: #000000;
}

.black-bg {
    background-color: #000000;
}

.white {
    color: #ffffff;
}

.white-bg {
    background-color: #ffffff;
}

.white-bg {
    background-color: #fff;
}

.white-bg-rgba {
    background: rgba(255, 255, 255, 0.7);
}

.light-gray-bg {
    background-color: #dbdbdb;
}

.semi-light-gray-bg {
    background-color: $medium-gray;
}

.mid-gray-bg {
    background-color: #c1c1c1;
}

.dark-gray-bg {
    background: #a9a9a9;
}

.gray-bg {
    background: #5b5b5b;
}

.orange-bg {
    background: #ff6a00;
}

.orange-bg-gr {
    background: #540082;
    background: -moz-linear-gradient(-45deg, #540082 0%, #ff6a00 100%);
    background: -webkit-linear-gradient(-45deg, #540082 0%, #ff6a00 100%);
    background: linear-gradient(135deg, #540082 0%, #ff6a00 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#540082', endColorstr='#ff6a00', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
}

.gray-bg-r {
    background: #f4f4f4;
    background: -moz-linear-gradient(-45deg, #f4f4f4 0%, #616161 100%);
    background: -webkit-linear-gradient(-45deg, #f4f4f4 0%, #616161 100%);
    background: linear-gradient(135deg, #f4f4f4 0%, #616161 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f4f4f4', endColorstr='#616161', GradientType=1);
}

.blue-bg-r {
    background: #1e5799;
    background: -moz-linear-gradient(-45deg, #1e5799 0%, #7e43ba 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #1e5799 0%, #7e43ba 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #1e5799 0%, #7e43ba 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1e5799', endColorstr='#7e43ba', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
}

.navy-bg {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#6eb1e5+0,1e5799+100 */
    background: #6eb1e5;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #6eb1e5 0%, #1e5799 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #6eb1e5 0%, #1e5799 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #6eb1e5 0%, #1e5799 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6eb1e5', endColorstr='#1e5799', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
}

.green-bg {
    background: #0ae67b;
    background: -moz-linear-gradient(-45deg, #0ae67b 0%, #42babd 100%);
    background: -webkit-linear-gradient(-45deg, #0ae67b 0%, #42babd 100%);
    background: linear-gradient(135deg, #0ae67b 0%, #42babd 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0ae67b', endColorstr='#42babd', GradientType=1);
}

.yellow-bg {
    background: #ffd65e;
    background: -moz-linear-gradient(-45deg, #ffd65e 0%, #febf04 100%);
    background: -webkit-linear-gradient(-45deg, #ffd65e 0%, #febf04 100%);
    background: linear-gradient(135deg, #ffd65e 0%, #febf04 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd65e', endColorstr='#febf04', GradientType=1);
}

.purple-bg-r {
    background: #4f76e2;
    /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover, #4f76e2 0%, #4723b2 100%);
    /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover, #4f76e2 0%, #4723b2 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center, #4f76e2 0%, #4723b2 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4f76e2', endColorstr='#4723b2', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
}

/*#################### EFFECTS #######################*/

.columns {
    -webkit-transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
    -moz-transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
    -o-transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
    transition: all 800ms cubic-bezier(0.875, 0.065, 0.165, 0.970);
}

#blured {
    position: absolute;
    bottom: -50px;
}

.blur {
    filter: blur(20px);
    -webkit-filter: blur(20px);
    -moz-filter: blur(20px);
    -o-filter: blur(20px);
    -ms-filter: blur(20px);
    filter: url(/css/blur.svg#blur);
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='10');
}

.blur_1 {
    filter: blur(5px);
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: url(/css/blur.svg#blur_1);
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='10');
}

.no-blur {
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    filter: blur(0);
}

.shadow_1 {
    -webkit-box-shadow: 0px 1px 3px 0 $medium-gray;
    box-shadow: 0px 1px 3px 0 $medium-gray;
}

.shadow_1-text {
    text-shadow: 1px 1px 0 $medium-gray;
}

.round-small {
    -webkit-border-radius: $global-radius;
    border-radius: $global-radius;
}

.round-medium {
    -webkit-border-radius: $global-radius * 3;
    border-radius: $global-radius * 3;
}

.round-medium-top {
    -webkit-border-radius: $global-radius * 3 $global-radius * 3 0 0;
    border-radius: $global-radius * 3 $global-radius * 3 0 0;
  }

.round-medium-left {
  -webkit-border-radius: $global-radius * 3 0 0 $global-radius * 3;
  border-radius: $global-radius * 3 0 0 $global-radius * 3;
}

.round-medium-right {
  -webkit-border-radius: 0 $global-radius * 3 $global-radius * 3 0 ;
  border-radius: 0 $global-radius * 3 $global-radius * 3 0;
}

.round-large {
    -webkit-border-radius: $global-radius * 10;
    border-radius: $global-radius * 10;
}

.round-right {
    -webkit-border-radius: 0px 4px 4px 0px;
    border-radius: 0px 4px 4px 0px;
}

.pageLoader.active {
    display: block;
    width: 100%;
    position: fixed;
    z-index: 50;
    height: 100%;
    background: rgba(57, 57, 57, 0.7);
}

.pageLoader {
    display: none;
    width: 100vh;
    position: fixed;
}

.loader {
    width: 100%;
    padding: 1em;
    position: fixed;
    background-color: none;
    z-index: 100;
    bottom: 0;
}

.loader img {
    position: relative;
    top: 50%;
    left: 50%;
}

/*####################################### COMPONENTS ##############################*/

#cookies-message {
    position: fixed;
    bottom: 0;
    z-index: 9;
    width: 30%;
    padding-bottom: 120px;
    left: $global-spacing * 2;
}

.site-msg-green {
    background-color: #F1FCF3;
    border: solid 1px #67D06C;
    border-radius: 2px;
    padding: 5px;
    padding-left: 30px;
    background-image: url(/images/ui/info-icon-green.png);
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 5px;
    margin: 5px 0;
}

.site-msg-red {
    background-color: #FCF1F1;
    border: solid 1px #D06767;
    border-radius: 2px;
    padding: 5px;
    padding-left: 30px;
    background-image: url(/images/ui/info-icon-red.png);
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 5px;
    margin: 5px 0;
}

.site-msg-blue {
    background-color: #F1F7FC;
    border: solid 1px #67A8D0;
    border-radius: 2px;
    padding: 5px;
    padding-left: 30px;
    background-image: url(/images/ui/info-icon.png);
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 5px;
    margin: 5px 0;
}

.site-msg-amber {
    background-color: #F9EEC4;
    border: solid 1px #DAA81F;
    border-radius: 2px;
    padding: 5px;
    padding-left: 30px;
    background-image: url(/images/ui/info-icon-amber.png);
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 5px;
    margin: 5px 0;
}

.close {
    width: auto;
    height: auto;
    padding: 5px;
    color: #BF393C;
    cursor: pointer;
    border-radius: 2px;
    margin-bottom: 1em;
}

.close:hover {
    background-color: #FFEDEE;
}

.close-button {
    z-index: 2;
    position: relative;
    right: 0.8em;
    float: right;
    top: 0;
}
.search-MU{
    vertical-align: middle;
    line-height: 1.5;
    margin-bottom: 0;
    margin-right: 2em;
}
.counter{
    vertical-align: middle;
    line-height: 1.5;
    margin-bottom: 0;
    font-size: 23px;
    padding-top: 0.1em;
}

.is-invalid-input,
.error {
    background: #FFF7F7;
    color: #D12F19;
    position: relative;
    z-index: 28;
    border-radius: 2px;
    border: solid 1px #d65f5f;
    behavior: url(/css/ie-css3.htc);
}

.valid {
    -webkit-animation: valid 4s 1 ease-in-out;
    animation: valid 4s 1 ease-in-out;
    background-color: #fff;
}

@-webkit-keyframes valid {
    from {
        -webkit-box-shadow: inset 0 0 10px 0 #27b85a;
        -moz-box-shadow: inset 0 0 10px 0 #27b85a;
        box-shadow: inset 0 0 10px 0 #27b85a;
    }

    to {
        background-color: #fff;
    }
}

@keyframes valid {
    from {
        -webkit-box-shadow: inset 0 0 10px 0 #27b85a;
        -moz-box-shadow: inset 0 0 10px 0 #27b85a;
        box-shadow: inset 0 0 10px 0 #27b85a;
    }

    to {
        background-color: #fff;
    }
}

.dpDiv select {
    width: 48%;
    margin: 0.3em;
}

.dpDiv button.button {
    outline: 0;
    color: #467f44;
    background: #f4f4f4;
    border: solid 1px #467f44;
    margin: 0;
    width: 31.3%;
}

legend {
    font-size: 12px;
}

.expandedDesc {
    height: 40px;
    overflow: hidden;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

/*####################################### TRANSITIONS ##############################*/

.pageTransition-enter{opacity:0;z-index:1;position:relative;left:0;right:0;top:-10px;bottom:0;z-index:29}
.pageTransition-enter-active{opacity:1;transition:all .5s linear 150ms;top:0}
.pageTransition-exit{opacity:1;z-index:0;position:relative;left:0;right:0;top:-10px;bottom:0;z-index:29}
.pageTransition-exit-active{opacity:0;transition:all .5s linear;top:100%}

.fade-enter {
    opacity: 0.01;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0.01;
    transition: opacity 500ms ease-in;
  }

.loadedin-enter{opacity:0;z-index:1;position:relative;left:0;right:0;top:-10px;bottom:0;z-index:29}
.loadedin-enter-active{opacity:1;transition:all .5s linear 150ms;top:0}
.loadedin-exit{opacity:1;z-index:0;position:relative;left:0;right:0;top:-10px;bottom:0;z-index:29}
.loadedin-exit-active{opacity:0;transition:all .5s linear;top:100%}

/*####################################### RESPONSIVE ##############################*/

.full-section {
    width: 100%;
    position: relative;
    height: 100vh;
    overflow: hidden;
  }

  .half-section {
    width: 100%;
    position: relative;
    height: 80vh;
    overflow: hidden;
  }

  .small-section {
    width: 100%;
    position: relative;
    height: 25vh;
    overflow: hidden;
  }

.head-spacing {
    height: 100px;
}

/* Small only */

@media screen and (max-width: 39.9375em) {
    .small-center {
        text-align: center;
    }

    .small-left {
        text-align: left;
    }

    .small-right {
        text-align: right;
    }

    .small-margin-top {
        margin-top: 0.8em;
    }

    .small-margin-top-2x {
        margin-top: 2em;
    }

    .small-margin-top-3x {
        margin-top: 3em;
    }

    .small-margin-bottom {
        margin-bottom: 0.8em;
    }

    .small-margin-bottom-2x {
        margin-bottom: 2em;
    }

    .small-margin-bottom-3x {
        margin-bottom: 3em;
    }

    .small-margin-right {
        margin-right: 0.8em;
    }

    .small-margin-right-2x {
        margin-right: 2em;
    }

    .small-margin-left {
        margin-left: 0.8em;
    }

    .small-margin-left-2x {
        margin-left: 2em;
    }

    .small-margin-distributed {
        margin: 0.8em 0;
    }

    .small-margin-distributed-2x {
        margin: 2em 0;
    }

    .small-margin-all {
        margin: 0.8em;
    }

    .small-no-margin {
        margin: 0;
    }

    .small-padding-top {
        padding-top: 0.8em;
    }

    .small-padding-top-2x {
        padding-top: 2em;
    }

    .small-padding-bottom {
        padding-bottom: 0.8em;
    }

    .small-padding-bottom-2 {
        padding-bottom: 2em;
    }

    .small-padding-distributed {
        padding: 0.8em 0;
    }

    .small-padding-distributed-2x {
        padding: 2em 0;
    }

    .small-padding-all {
        padding: 0.8em;
    }

    .small-padding-all-2x {
        padding: 2em;
    }

    .small-padding-all-3x {
        padding: 3em;
    }

    .small-padding-left {
        padding: 0 0 0 0.8em;
    }

    .small-no-padding {
        padding: 0;
    }

    // #### COMPONENTS
    #cookies-message {
        position: fixed;
        bottom: -10px;
        z-index: 9;
        width: auto;
        padding-bottom: 80px;
        left: 2em;
        right: 2em;
    }

}

/* Medium and up */

@media screen and (min-width: 40em) {}

/* Medium only */

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .medium-center {
        text-align: center;
    }

    .medium-left {
        text-align: left;
    }

    .medium-right {
        text-align: right;
    }

    .medium-margin-top {
        margin-top: 0.8em;
    }

    .medium-margin-top-2x {
        margin-top: 2em;
    }

    .medium-margin-top-3x {
        margin-top: 3em;
    }

    .medium-margin-bottom {
        margin-bottom: 0.8em;
    }

    .medium-margin-bottom-2x {
        margin-bottom: 2em;
    }

    .medium-margin-bottom-3x {
        margin-bottom: 3em;
    }

    .medium-margin-right {
        margin-right: 0.8em;
    }

    .medium-margin-right-2x {
        margin-right: 2em;
    }

    .medium-margin-left {
        margin-left: 0.8em;
    }

    .medium-margin-left-2x {
        margin-left: 2em;
    }

    .medium-margin-distributed {
        margin: 0.8em 0;
    }

    .medium-margin-distributed-2x {
        margin: 2em 0;
    }

    .medium-margin-all {
        margin: 0.8em;
    }

    .medium-no-margin {
        margin: 0;
    }

    //COMPONENTS 
    #cookies-message {
        position: fixed;
        bottom: -10px;
        z-index: 9;
        width: auto;
        padding-bottom: 80px;
        left: 2em;
        right: 2em;
    }
}

/* Large and up */

@media screen and (min-width: 64em) {
    .large-center {
        text-align: center;
    }

    .large-left {
        text-align: left;
    }

    .large-right {
        text-align: right;
    }
}

/* Large only */

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .large-margin-top {
        margin-top: 0.8em;
    }

    .large-margin-top-2x {
        margin-top: 2em;
    }

    .large-margin-top-3x {
        margin-top: 3em;
    }

    .large-margin-bottom {
        margin-bottom: 0.8em;
    }

    .large-margin-bottom-2x {
        margin-bottom: 2em;
    }

    .large-margin-bottom-3x {
        margin-bottom: 3em;
    }

    .large-margin-right {
        margin-right: 0.8em;
    }

    .large-margin-right-2x {
        margin-right: 2em;
    }

    .large-margin-left {
        margin-left: 0.8em;
    }

    .large-margin-left-2x {
        margin-left: 2em;
    }

    .large-margin-distributed {
        margin: 0.8em 0;
    }

    .large-margin-distributed-2x {
        margin: 2em 0;
    }

    .large-margin-all {
        margin: 0.8em;
    }

    .large-no-margin {
        margin: 0;
    }
}