#nav-lines {
  width: 35px;
    height: 35px;
    position: relative;
    cursor: pointer;
    z-index: 30;
}
#nav-lines .rect {
  stroke-width: 0;
  stroke: #3f51b5;
  fill: transparent;
  transform-origin: 50% 50%;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
#nav-lines .cross-line {
  stroke: #dd3d2b;
  stroke-width: 0;
  transform-origin: 50% 50%;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#nav-lines .nav-line {
  stroke: #3f51b5;
  transform-origin: 50% 50%;
  stroke-width: 4px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#nav-lines.active .cross-line {
  stroke-width: 4px;
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}
#nav-lines.active .nav-line, #nav-lines.active #nav-line-1, #nav-lines.active #nav-line-3 {
  -moz-transform: scale(0.01);
  -ms-transform: scale(0.01);
  -webkit-transform: scale(0.01);
  transform: scale(0.01);
}


/* Small only */
@media screen and (max-width: 39.9375em) {

  width: 30px;
  height: 30px;
  position: relative;
  cursor: pointer;
  z-index: 30;

}

/* Medium and up */
@media screen and (min-width: 40em) {}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {}

/* Large and up */
@media screen and (min-width: 64em) {}

/* Large only */
@media screen and (min-width: 64em) and (max-width: 74.9375em) {}